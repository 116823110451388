export const whatsappNumber = "+971522081705";
export const whatsappNumberText = "+971 522 081 705";
export const whatsappForLead="+971504506540"
export const secondaryFormTypeRent="secondary-form-rent"
export const secondaryFormTypeSale="secondary-form-sale"

  // EXAMPLE array for area sizes (adjust to your needs)
export const areaOptions = [
  // Very small
  { value: 30, label: "30" },        // Minimum
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 300, label: "300" },
  { value: 400, label: "400" },
  { value: 500, label: "500" },
  { value: 600, label: "600" },
  { value: 700, label: "700" },
  { value: 800, label: "800" },
  { value: 900, label: "900" },
  { value: 1000, label: "1,000" },
  // Medium jumps
  { value: 1250, label: "1,250" },
  { value: 1500, label: "1,500" },
  { value: 2000, label: "2,000" },
  { value: 2500, label: "2,500" },
  { value: 3000, label: "3,000" },
  { value: 4000, label: "4,000" },
  { value: 5000, label: "5,000" },
  // Larger jumps for bigger units
  { value: 7_500, label: "7,500" },
  { value: 10_000, label: "10,000" },
  { value: 15_000, label: "15,000" },
  { value: 20_000, label: "20,000" },
  { value: 30_000, label: "30,000" },
  { value: 50_000, label: "50,000" },
  { value: 60_000, label: "60,000" },
  { value: 80_000, label: "80,000" },
  { value: 100_000, label: "100,000" },
  { value: 125_000, label: "125,000" },
  { value: 150_000, label: "150,000" }
];

export const propertyTypes = [
  {
    id: 1,
    label: "Any",
    value: "any",
    type: "Any",
  },
  {
    id: 2,
    label: "Apartments",
    value: "apartment",
    type: "Residential",
  },
  {
    id: 3,
    label: "Duplex",
    value: "duplex",
    type: "Residential",
  },
  {
    id: 4,
    label: "Labour Camp",
    value: "labour-camp",
    type: "Commercial",
  },
  {
    id: 5,
    label: "Land Residential",
    value: "land-residential",
    type: "Residential",
  },
  {
    id: 6,
    label: "Office",
    value: "office",
    type: "Commercial",
  },
  {
    id: 7,
    label: "Townhouse",
    value: "townhouse",
    type: "Residential",
  },
  {
    id: 8,
    label: "Duplex",
    value: "duplex",
    type: "Residential",
  },
  {
    id: 9,
    label: "Villa",
    value: "villa",
    type: "Residential",
  },
  {
    id: 10,
    label: "Warehouse",
    value: "warehouse",
    type: "Commercial",
  },
  {
    id: 11,
    label: "Penthouse",
    value: "penthouse",
    type: "Residential",
  },
  {
    id: 12,
    label: "Retail",
    value: "retail",
    type: "Commercial",
  },
  {
    id: 13,
    label: "Shop",
    value: "shop",
    type: "Commercial",
  },
  {
    id: 14,
    label: "Full Floor",
    value: "commercial-full-floor",
    type: "Commercial",
  },
  {
    id: 15,
    label: "Full Floor",
    value: "residential-full-floor",
    type: "Residential",
  },
  {
    id: 16,
    label: "Half Floor",
    value: "commercial-half-floor",
    type: "Commercial",
  },
  {
    id: 17,
    label: "Half Floor",
    value: "residential-half-floor",
    type: "Residential",
  },
];

export const propertyTypesAR = [
  {
    id: 1,
    label: "أي",
    value: "any",
    type: "Any",
  },
  {
    id: 2,
    label: "شقق",
    value: "apartment",
    type: "Residential",
  },
  {
    id: 3,
    label: "دوبلكس",
    value: "duplex",
    type: "Residential",
  },
  {
    id: 4,
    label: "معسكر عمال",
    value: "labour-camp",
    type: "Commercial",
  },
  {
    id: 5,
    label: "أرض سكنية",
    value: "land-residential",
    type: "Residential",
  },
  {
    id: 6,
    label: "مكتب",
    value: "office",
    type: "Commercial",
  },
  {
    id: 7,
    label: "تاون هاوس",
    value: "townhouse",
    type: "Residential",
  },
  {
    id: 8,
    label: "دوبلكس",
    value: "duplex",
    type: "Residential",
  },
  {
    id: 9,
    label: "فيلا",
    value: "villa",
    type: "Residential",
  },
  {
    id: 10,
    label: "مستودع",
    value: "warehouse",
    type: "Commercial",
  },
  {
    id: 11,
    label: "بنتهاوس",
    value: "penthouse",
    type: "Residential",
  },
  {
    id: 12,
    label: "تجاري",
    value: "retail",
    type: "Commercial",
  },
  {
    id: 13,
    label: "محل تجاري",
    value: "shop",
    type: "Commercial",
  },
  {
    id: 14,
    label: "طابق كامل تجاري",
    value: "commercial-full-floor",
    type: "Commercial",
  },
  {
    id: 15,
    label: "طابق كامل سكني",
    value: "residential-full-floor",
    type: "Residential",
  },
  {
    id: 16,
    label: "نصف طابق تجاري",
    value: "commercial-half-floor",
    type: "Commercial",
  },
  {
    id: 17,
    label: "نصف طابق سكني",
    value: "residential-half-floor",
    type: "Residential",
  },
];

export const bedrooms = [
  {
    id: 1,
    label: "1",
    value: "1",
  },
  {
    id: 2,
    label: "2",
    value: "2",
  },
  {
    id: 3,
    label: "3",
    value: "3",
  },
  {
    id: 4,
    label: "4",
    value: "4",
  },
  {
    id: 5,
    label: "5",
    value: "5",
  },
  {
    id: 6,
    label: "6",
    value: "6",
  },
  {
    id: 7,
    label: "7",
    value: "7",
  },
  {
    id: 8,
    label: "8",
    value: "8",
  },
  {
    id: 9,
    label: "9",
    value: "9",
  },
  {
    id: 10,
    label: "10",
    value: "10",
  },
];

export const bedroomsAR = [
  {
    id: 1,
    label: "١",
    value: "1",
  },
  {
    id: 2,
    label: "٢",
    value: "2",
  },
  {
    id: 3,
    label: "٣",
    value: "3",
  },
  {
    id: 4,
    label: "٤",
    value: "4",
  },
  {
    id: 5,
    label: "٥",
    value: "5",
  },
  {
    id: 6,
    label: "٦",
    value: "6",
  },
  {
    id: 7,
    label: "٧",
    value: "7",
  },
  {
    id: 8,
    label: "٨",
    value: "8",
  },
  {
    id: 9,
    label: "٩",
    value: "9",
  },
  {
    id: 10,
    label: "١٠",
    value: "10",
  },
];

export const bedrooms_ar = [
  {
    id: 1,
    label: "١",
    value: "1",
  },
  {
    id: 2,
    label: "٢",
    value: "2",
  },
  {
    id: 3,
    label: "٣",
    value: "3",
  },
  {
    id: 4,
    label: "٤",
    value: "4",
  },
  {
    id: 5,
    label: "٥",
    value: "5",
  },
  {
    id: 6,
    label: "٦",
    value: "6",
  },
  {
    id: 7,
    label: "٧",
    value: "7",
  },
  {
    id: 8,
    label: "٨",
    value: "8",
  },
  {
    id: 9,
    label: "٩",
    value: "9",
  },
  {
    id: 10,
    label: "١٠",
    value: "10",
  },
];

export const price = [
  {
    id: 1,
    label: "20,000",
    value: "20000",
  },
  {
    id: 2,
    label: "30,000",
    value: "30000",
  },
  {
    id: 3,
    label: "40,000",
    value: "40000",
  },
  {
    id: 4,
    label: "50,000",
    value: "50000",
  },
  {
    id: 5,
    label: "60,000",
    value: "60000",
  },
  {
    id: 6,
    label: "70,000",
    value: "70000",
  },
  {
    id: 7,
    label: "80,000",
    value: "80000",
  },
  {
    id: 8,
    label: "90,000",
    value: "90000",
  },
  {
    id: 9,
    label: "100,000",
    value: "100000",
  },
  {
    id: 10,
    label: "120,000",
    value: "120000",
  },
  {
    id: 11,
    label: "160,000",
    value: "160000",
  },
  {
    id: 12,
    label: "180,000",
    value: "180000",
  },
  {
    id: 13,
    label: "200,000",
    value: "200000",
  },
  {
    id: 14,
    label: "250,000",
    value: "250000",
  },
  {
    id: 15,
    label: "300,000",
    value: "300000",
  },
  {
    id: 16,
    label: "400,000",
    value: "400000",
  },
  {
    id: 17,
    label: "500,000",
    value: "500000",
  },
  {
    id: 18,
    label: "600,000",
    value: "600000",
  },
  {
    id: 19,
    label: "700,000",
    value: "700000",
  },
  {
    id: 20,
    label: "800,000",
    value: "800000",
  },
  {
    id: 21,
    label: "900,000",
    value: "900000",
  },
  {
    id: 22,
    label: "1,000,000",
    value: "1000000",
  },
  {
    id: 23,
    label: "1,500,000",
    value: "1500000",
  },
  {
    id: 24,
    label: "2,000,000",
    value: "2000000",
  },
  {
    id: 25,
    label: "3,000,000",
    value: "3000000",
  },
  {
    id: 26,
    label: "4,000,000",
    value: "4000000",
  },
  {
    id: 27,
    label: "5,000,000",
    value: "5000000",
  },
  {
    id: 28,
    label: "10,000,000",
    value: "10000000",
  },
  {
    id: 29,
    label: "15,000,000",
    value: "15000000",
  },
  {
    id: 30,
    label: "20,000,000",
    value: "20000000",
  },
  {
    id: 31,
    label: "30,000,000",
    value: "30000000",
  },
  {
    id: 32,
    label: "40,000,000",
    value: "40000000",
  },
  {
    id: 33,
    label: "50,000,000",
    value: "50000000",
  },
];

export const price_ar = [
  {
    id: 1,
    label: "٢٠،٠٠٠",
    value: "20000",
  },
  {
    id: 2,
    label: "٣٠،٠٠٠",
    value: "30000",
  },
  {
    id: 3,
    label: "٤٠،٠٠٠",
    value: "40000",
  },
  {
    id: 4,
    label: "٥٠،٠٠٠",
    value: "50000",
  },
  {
    id: 5,
    label: "٦٠،٠٠٠",
    value: "60000",
  },
  {
    id: 6,
    label: "٧٠،٠٠٠",
    value: "70000",
  },
  {
    id: 7,
    label: "٨٠،٠٠٠",
    value: "80000",
  },
  {
    id: 8,
    label: "٩٠،٠٠٠",
    value: "90000",
  },
  {
    id: 9,
    label: "١٠٠،٠٠٠",
    value: "100000",
  },
  {
    id: 10,
    label: "١٢٠،٠٠٠",
    value: "120000",
  },
  {
    id: 11,
    label: "١٦٠،٠٠٠",
    value: "160000",
  },
  {
    id: 12,
    label: "١٨٠،٠٠٠",
    value: "180000",
  },
  {
    id: 13,
    label: "٢٠٠،٠٠٠",
    value: "200000",
  },
  {
    id: 14,
    label: "٢٥٠،٠٠٠",
    value: "250000",
  },
  {
    id: 15,
    label: "٣٠٠،٠٠٠",
    value: "300000",
  },
  {
    id: 16,
    label: "٤٠٠،٠٠٠",
    value: "400000",
  },
  {
    id: 17,
    label: "٥٠٠،٠٠٠",
    value: "500000",
  },
  {
    id: 18,
    label: "٦٠٠،٠٠٠",
    value: "600000",
  },
  {
    id: 19,
    label: "٧٠٠،٠٠٠",
    value: "700000",
  },
  {
    id: 20,
    label: "٨٠٠،٠٠٠",
    value: "800000",
  },
  {
    id: 21,
    label: "٩٠٠،٠٠٠",
    value: "900000",
  },
  {
    id: 22,
    label: "١،٠٠٠،٠٠٠",
    value: "1000000",
  },
  {
    id: 23,
    label: "١،٥٠٠،٠٠٠",
    value: "1500000",
  },
  {
    id: 24,
    label: "٢،٠٠٠،٠٠٠",
    value: "2000000",
  },
  {
    id: 25,
    label: "٣،٠٠٠،٠٠٠",
    value: "3000000",
  },
  {
    id: 26,
    label: "٤،٠٠٠،٠٠٠",
    value: "4000000",
  },
  {
    id: 27,
    label: "٥،٠٠٠،٠٠٠",
    value: "5000000",
  },
  {
    id: 28,
    label: "١٠،٠٠٠،٠٠٠",
    value: "10000000",
  },
  {
    id: 29,
    label: "١٥،٠٠٠،٠٠٠",
    value: "15000000",
  },
  {
    id: 30,
    label: "٢٠،٠٠٠،٠٠٠",
    value: "20000000",
  },
  {
    id: 31,
    label: "٣٠،٠٠٠،٠٠٠",
    value: "30000000",
  },
  {
    id: 32,
    label: "٤٠،٠٠٠،٠٠٠",
    value: "40000000",
  },
  {
    id: 33,
    label: "٥٠،٠٠٠،٠٠٠",
    value: "50000000",
  },
];

export const navLinks = [
  {
    id: 1,
    label: "RESIDENTIAL",
    path: "/buy/residential/properties-for-sale",
    subLinks: [
      {
        id: 1,
        label: "Residential For Sale",
        path: "/buy/residential/properties-for-sale",
      },
      {
        id: 2,
        label: "Residential For Rent",
        path: "/rent/residential/properties-for-rent",
      },
    ]
  },
  {
    id: 2,
    label: "COMMERCIAL",
    path: "/buy/commercial/properties-for-sale",
    subLinks: [
      {
        id: 1,
        label: "Commercial for Sale",
        path: "/buy/commercial/properties-for-sale",
      },
      {
        id: 2,
        label: "Commercial for Rent",
        path: "/rent/commercial/properties-for-rent",
      },
    ],
  },
  {
    id: 3,
    label: "OFF PLAN",
    path: "/offplan",
  },
  {
    id: 4,
    label: "BLOGS",
    path: "/blogs",
  },
  {
    id: 6,
    label: "MORE",
    path: "",
    subLinks: [
      {
        id: 1,
        label: "Developers",
        path: "/developers",
      },
      {
        id: 2,
        label: "Areas & Communities",
        path: "/areas-and-communities",
      },
      {
        id: 3,
        label: "Mortgage Calculator",
        path: "/mortgage-calculator",
      },
      {
        id: 5,
        label: "Our Team",
        path: "/our-team",
      },
    ],
  },
  {
    id: 7,
    label: "CONTACT US",
    path: "/contact-us",
  },
  // {
  //   id: 9,
  //   label: "LANGUAGE",
  //   subLinks: [
  //     {
  //       id: 1,
  //       label: "English",
  //       path: "en",
  //     },
  //     {
  //       id: 2,
  //       label: "العربية",
  //       path: "ar",
  //     },
  //   ]
  // }
];

export const navLinks_ar = [
  {
    id: 1,
    label: "سكني",
    path: "/buy/residential/properties-for-sale",
    subLinks: [
      {
        id: 1,
        label: "سكني للبيع",
        path: "/buy/residential/properties-for-sale",
      },
      {
        id: 2,
        label: "سكني للإيجار",
        path: "/rent/residential/properties-for-rent",
      },
    ]
  },
  {
    id: 3,
    label: "تجاري",
    path: "/buy/commercial/properties-for-sale",
    subLinks: [
      {
        id: 1,
        label: "تجاري للبيع",
        path: "/buy/commercial/properties-for-sale",
      },
      {
        id: 2,
        label: "تجاري للإيجار",
        path: "/rent/commercial/properties-for-rent",
      },
    ],
  },
  {
    id: 4,
    label: "قيد الانشاء",
    path: "/offplan",
  },
  {
    id: 5,
    label: "مدونات",
    path: "/blogs",
  },
  {
    id: 6,
    label: "فريقنا",
    path: "/our-team",
  },
  {
    id: 7,
    label: "المزيد",
    path: "",
    subLinks: [
      {
        id: 1,
        label: "المطورين",
        path: "/developers",
      },
      {
        id: 2,
        label: "المناطق والمجتمعات",
        path: "/areas-and-communities",
      },
      {
        id: 3,
        label: "آلة حاسبة الرهن العقاري",
        path: "/mortgage-calculator",
      },
    ],
  },
  {
    id: 8,
    label: "تواصل معنا",
    path: "/contact-us",
  },
  // {
  //   id: 9,
  //   label: "اللغة",
  //   subLinks: [
  //     {
  //       id: 1,
  //       label: "English",
  //       path: "en",
  //     },
  //     {
  //       id: 2,
  //       label: "العربية",
  //       path: "ar",
  //     },
  //   ]
  // }
];
